<template>
  <div>
    <div class="entrepot-title">
      <div
        @click="warehouseClick(0)"
        :class="[limit.status == 0 ? 'entrepot-color' : '']"
      >
        待审核
      </div>
      <div
        @click="warehouseClick(1)"
        :class="[limit.status == 1 ? 'entrepot-color' : '']"
      >
        已审核
      </div>
      <div
        @click="warehouseClick(3)"
        :class="[limit.status == 3 ? 'entrepot-color' : '']"
      >
        已上传报销凭证
      </div>

      <el-date-picker
      v-show="limit.status == 3"
      v-model="Seletime"
      clearable
      type="daterange"
      align="right"
      unlink-panels
      value-format="yyyy-MM-dd"
      @blur="noblurClick"
      range-separator="至"
      start-placeholder="开始日期"
      end-placeholder="结束日期"
      :picker-options="pickerOptions">
      </el-date-picker>
    </div>
    <el-table
      :data="tableData"
      stripe
      style="width: 100%"
      element-loading-text="拼命加载中"
      element-loading-background="#fff"
      border
    >
      <el-table-column label="日期" align="center" width="100">
        <template scope="scope">
          {{ scope.row.create_time | timeFilter }}
        </template>
      </el-table-column>
      <el-table-column label="商品图片" align="center" width="120">
        <template scope="scope">
          <el-image :src="scope.row.goods_url | replaceUrl"></el-image>
        </template>
      </el-table-column>
      <el-table-column
        prop="bar_code"
        label="条形码"
        align="center"
        width="120"
      ></el-table-column>
      <el-table-column label="二维码" align="center">
        <template scope="scope">
          <vue-qr
            v-if="scope.row.qr_code"
            :margin="10"
            :text="scope.row.qr_code"
            :size="100"
          ></vue-qr>
          <div v-else>暂无数据</div>
        </template>
      </el-table-column>
      <el-table-column
        prop="aname"
        label="所属区域"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="dname"
        label="所属部门"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="warehouse_name"
        label="商城名称"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="sell_name"
        label="兑奖员工"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="reward"
        label="奖金"
        align="center"
      ></el-table-column>
      <el-table-column label="兑奖照片" align="center">
        <template slot-scope="scope">
          <el-button @click="seeClick(scope.row.upload_url)" type="text" size="small"
            >点击查看</el-button
          >
        </template>
      </el-table-column>
      <el-table-column
        prop="goods_type"
        label="型号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="goods_brand"
        label="品牌"
        align="center"
      ></el-table-column>
      <el-table-column
        prop=""
        label="审核"
        align="center"
        width="180"
        v-if="limit.status == 0 || limit.status == 1"
      >
        <template scope="scope">
          <div class="btnbox" v-if="limit.status == 0">
            <el-button
              class="adoptButton-class"
              @click="notpass(scope.row.sid, 1)"
              >通过</el-button
            >
            <el-button
              class="NoButton-class"
              @click="notpass(scope.row.sid, 2)"
              >不通过</el-button
            >
          </div>
          <div class="btnbox" v-else-if="limit.status == 1">
            <el-button
              class="adoptButton1-class"
              @click="btnClick(scope.row.sid, 3)"
              >通过</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div class="box">总奖金：{{rewardcount}}</div>
    <!-- 分页 -->
    <el-pagination
      class="table-limit"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="currentPage"
      :page-sizes="pageArray"
      :page-size="this.limit.page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
        
        <!-- 弹窗 -->
    <el-dialog :visible.sync="dialogTableVisible" width="500px">
      <template>
         <el-image class="img" :src="dialogimg"></el-image>
      </template>
    </el-dialog>
        <!-- 弹窗 -->
    <el-dialog :visible.sync="show" width="500px">
      <template>
            <div class="title">请输入拒绝理由：</div>
            <el-input
              class="input"
              placeholder="输入拒绝理由"
              v-model="remark"
            >
            </el-input>
            <div class="btnbox22">
                 <el-button type="primary" @click="auditEdit">确定</el-button>
                 <el-button @click="show = false">取消</el-button>
            </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { check, checkedit } from "@/api/finance.js";
export default {
  data() {
    return {
      pageArray:this.$store.state.pageArray,
      pageType: 0,
      rewardcount:0,
      remark:'',//拒绝理由
      limit: {
        page: 1,
        page_size: 10,
        status: 0,
      },
      total: 0,
      loading: true,
      currentPage: 1,
      tableData: [],
      dialogTableVisible:false,
      show:false,
      dialogimg:'',
      auditFormData:{
        id:'',
        status:''
      },
      pickerOptions: {
          disabledDate(time) {
              return time.getTime() > Date.now();
          }
      },
      Seletime:[],
    };
  },
  created() {
    this.getlistinfo();
    // 获取默认时间
    this.getMtime()
  },
  methods: {
    warehouseClick(e) {
      this.rewardcount = 0;
      this.limit.status = e;
      this.getlistinfo();
    },
    getlistinfo() {
      check(this.limit).then((res) => {
        let data = res.data.data;
        this.total = res.data.total;
        this.tableData = data;
        if(!data){
          this.rewardcount = 0
        }else{
          this.rewardcount = res.data.rewardcount.rewardcount
        }
      });
    },
    // 时间选择获取最近一个月
    getMtime(){
        let end = new Date();
        let nowend = this.formatDate(end);
        let start = new Date();
        start.setDate(1);
        start.setHours(0);
        start.setSeconds(0);
        start.setMinutes(0);
        start = start.getTime()
        let nowstart = this.formatDate(start);
        this.Seletime = [nowstart,nowend]
    },
    noblurClick(){
      let timeMerge = this.Seletime[0] + '~' + this.Seletime[1]
      let data = {
        page: this.limit.page,
        page_size: this.limit.page_size,
        status: this.limit.status,
        create_time:timeMerge
      }
       check(data).then((res) => {
        let data = res.data.data;
        this.total = res.data.total;
        this.tableData = data;
        if(!data){
          this.rewardcount = 0
        }else{
          this.rewardcount = res.data.rewardcount.rewardcount
        }
      });
    },

    // 格式化日期
    formatDate(date) {
        var date = new Date(date);
        var YY = date.getFullYear() + '-';
        var MM = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
        var DD = (date.getDate() < 10 ? '0' + (date.getDate()) : date.getDate());
        return YY + MM + DD ;
    },

    //点击不通过 / 或者不通过
    notpass(sid, status){
      this.auditFormData.id = sid
      if(status == 1){
        this.auditFormData.status = 1
        this.auditEdit()
      }else{
        this.show = true
        this.auditFormData.status = 2
        this.auditFormData.id = sid
      }
    },
    //待审核状态修改
    auditEdit(){
      if(this.auditFormData.status ==2){
        this.auditFormData.remark1  = this.remark
      }
      this.$confirm("是否确定执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        checkedit(this.auditFormData).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.show  = false
          this.auditFormData.remark = ''
          this.getlistinfo();
        });
      });
    },


    //点击事件
    btnClick(sid, status) {
      let id = sid;
      this.$confirm("是否确定执行此操作?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        checkedit({ status: status, id: id }).then((res) => {
          this.$message({
            type: "success",
            message: "操作成功!",
          });
          this.getlistinfo();
        });
      });

    },
    seeClick(e){
      this.dialogTableVisible = true
      this.dialogimg = this.$store.state.baseUrl + e
      console.log(e);
    },
    //分页
    handleSizeChange(val) {
      this.limit.page_size = val;
      this.loading = true;
      this.getlistinfo();
    },
    handleCurrentChange(val) {
      this.limit.page = val;
      this.loading = true;
      this.getlistinfo();
    },
  },
};
</script>

<style scoped>
.entrepot-title {
  height: 120px;
  display: flex;
  align-items: center;
  font-weight: bold;
  
}
.entrepot-title div {
  margin-left: 50px;
}
.entrepot-color {
  color: #428cff;
}
.btnbox {
  width: 200px;
  display: flex;
  margin: 0 auto;
}
.btnbox .adoptButton-class {
  width: 86px;
  height: 38px;
  background: linear-gradient(90deg, #ff861b, #ffb527);
  border-radius: 6px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}
.btnbox .adoptButton1-class {
  width: 86px;
  height: 38px;
  background: linear-gradient(90deg, #ff861b, #ffb527);
  border-radius: 6px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  margin: 0 auto;
}
.btnbox .NoButton-class {
  width: 86px;
  height: 38px;
  background: #ccc9c9;
  border-radius: 6px;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
}
.img{
  width: 500px;
  height: 500px;
  margin-bottom: 50px;
  display: block;
  margin: 0 auto;
}
.box{
  float: right;
  background: linear-gradient(90deg, #FF861B, #FFB527);
  box-shadow: 0px 5px 30px 0px rgba(255, 142, 66, 0.4);
  border-radius: 6px;
  padding: 15px;
  font-size: 20px;
  font-family: PingFang SC;
  font-weight: bold;
  color: #FFFFFF;
  margin: 30px 50px;
}
.title{
  width: 100%;
  height: 50px;
}
.btnbox22{
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.btnbox22 button{
  margin: 0 30px;
}
</style>